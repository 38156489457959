import _ from "lodash";
import VisibilitySensor from 'react-visibility-sensor';
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useAsyncMemo } from 'use-async-memo';
import EntityFilterMenuDisplay from '../../components/EntityFilterMenuDisplay';
import { useEntityFullBySlug } from '../entity/Entity';
import { CurrentCartDetails, CurrentCartDetailsDrawer } from "./CurrentCartDetails";
import { CartProvider, useCart } from "./useCartData";
import { stringifyAttrValParams, withProvider } from "../../libs/utils";
import { useScrollUtils } from "../panel/useScrollUtils";
import { Pagination } from "../../components/ui/Pagination";
// import { PageFiltered } from "../entity/PageFiltered";
import { CartItemThumbGridMemo } from "./CartItemThumbGrid";
import BadgeLoading from "../../components/ui/BadgeLoading";
import filterDataToQueryFormatter from "../../components/Filters/filterDataToQueryFormatter";
import getModelQueryCache from "../../libs/ModelQueryCache";
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { usePrevious } from "../../libs/usePrevious";
import ContactBTN from "../../components/ContactBTN";


export default function () {
  return {
    type: 'productGrid',
    variation: 'withFilters',
    template: ProductGridWithFilters,
    params: {
      filterMenuSlug: {
        type: 'string',
        defaults: 'search'
      },
      filterPath: {
        type: 'string',
        defaults: '/m/products/filter'
      },
      viewPath: {
        type: 'string',
        defaults: '/m/products/view'
      },
      cartPath: {
        type: 'string',
        defaults: '/m/carts/view'
      },
      showFilters: {
        type: 'boolean',
        defaults: true
      },
      imgProportion: {
        type: 'string',
        options: ['vertical-square', 'vertical-wide'],
        defaults: 'vertical-square'
      }
    }
  };
};

export const ProductGridWithFilters = withProvider(ProductGridWithFiltersRaw, CartProvider);

function ProductGridWithFiltersRaw(props) {
  let {
    data,
    history,
    parsedParams,
    isAllowed,
    Model,
    isMinBreakpointActive,
    location,
    filterMenuSlug,
    filterPath,
    viewPath,
    cartPath,
    showFilters,
    imgProportion
  } = props;

  const blockId = useMemo(() => (_.uniqueId('item-grid')), []);
  const topElementRef = useRef();
  const { scrollTo } = useScrollUtils();
  const { ItemVariantModel, fetchItems, items, bags, cartDoc, saveNewCart, saveCurrentCart, closeCart, totalPriceItems } = useCart();
  const entitySlug = ItemVariantModel.collectionName;
  const entitySpects = useEntityFullBySlug({ entitySlug, filterMenuSlug });
  const { filterMenuTaxonomyTypes, taxonomyTypesDocList } = entitySpects;
  const [ currentPage, setPage ] = useState(1);
  const [ perPage, setPerPage ] = useState(30);
  const [ lastQueryString, setLastQueryString ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ pagesData, setPages ] = useState(null);
  const [ emptyResults, setEmptyResults ] = useState(false);
  const [ ItemQueryLibrary, setItemQueryLibrary ] = useState(null);
  const [ totalCount, setTotalCount ] = useState(0);
  const prevParsedParams = usePrevious(parsedParams);

  useEffect(() => {
    if (!ItemQueryLibrary) {
      const ModelExtended = Model.extend(entitySlug);
      setItemQueryLibrary(getModelQueryCache(ModelExtended, true));
    }
  }, [entitySlug]);

  const fetchFilteredDocs = async (queryParams, page) => {
    setLoading(true);
    const filterQuery = filterDataToQueryFormatter(queryParams, filterMenuTaxonomyTypes);
    setLastQueryString(JSON.stringify(filterQuery));
    let fetchedPages = await ItemQueryLibrary.filterAndGetPages(filterQuery, {
      page: page,
      limit: perPage,
      orderBy: { field: 'createdAt', direction: 'desc' },
    });
    fetchedPages = fetchedPages.map(page => (
      page.filter(doc => {
        return !doc.data?.deleted && doc.data.mainAvailable && doc.data.variantAvailable;
      })
    ));
    setEmptyResults(fetchedPages.length === 1 && fetchedPages[0].length === 0);
    setPages(fetchedPages);
    setLoading(false);
  };

  useEffect(() => {
    if (filterMenuTaxonomyTypes?.length) {
      // move to next page
      if (prevParsedParams === parsedParams) {
        fetchFilteredDocs(parsedParams, currentPage);
      }
      // reset to page 1
      else {
        fetchFilteredDocs(parsedParams, 1);
        topElementRef.current?.scrollIntoView({ behavior: 'instant', block: 'start' });
      }
    }
  }, [currentPage, parsedParams, ItemQueryLibrary, filterMenuTaxonomyTypes]);

  const nextPage = () => {
    if (currentPage <= totalPages) {
      setPage(currentPage + 1);
    }
  };

  const totalPages = useAsyncMemo(() => {
    const calcTotalPages = async (lastQueryString) => {
      if (!lastQueryString) { return null; }
      const ModelExtended = Model.extend(entitySlug);
      let lastQuery = JSON.parse(lastQueryString) || {};
      let count = await ModelExtended.filterByAttributesCount({ ...lastQuery });
      let countDeleted = await ModelExtended.filterByAttributesCount({ ...lastQuery, deleted: 'true' });
      count = count - countDeleted;
      setTotalCount(count);
      return count / perPage;
    };
    return calcTotalPages(lastQueryString);
  }, [lastQueryString]);

  const onFormClear = () => {
    history.push(filterPath);
  };

  const onFormChange = (formValues) => {
    if (formValues) {
      const attrValParams = stringifyAttrValParams(formValues);
      const url = attrValParams.length ? `${filterPath}/${attrValParams}` : filterPath;
      history.push(url);
      scrollTo(blockId);
    }
  };

  const onShow = (doc) => {
    history.push(`${viewPath}/main/${doc.data.mainItemId}/variant/${doc.id}`);
  };

  const goToItemByParams = (mainId, variantId, params) => {
    history.push(`${viewPath}/main/${mainId}/variant/${variantId}`);
  };

  if (entitySpects.isLoading) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-primary" />
      </div>
    );
  }

  return (
    <div id={blockId} className={`container-width-wide px-4 pb-8 lg:pt-4 flex flex-col ${showFilters ? 'lg:flex-row' : ''}`}>
      <div ref={topElementRef} className="relative -top-14 lg:-top-16"></div>

      {showFilters ? (
        <div className="lg:basis-4/12 my-4 lg:my-0">
          {totalPriceItems ? (
            <div className="mt-2 mb-4 px-2">
              <CurrentCartDetails cartPath={cartPath} history={history} />
            </div>
          ) : null}
          <EntityFilterMenuDisplay
            {...entitySpects}
            totalCount={totalCount}
            style={'search-collapsible-drawer'}
            filterData={parsedParams}
            defaultPath={filterPath}
            onFormClear={onFormClear}
            onFormChange={onFormChange}
          />
        </div>
      ) : null}

      <div className={`${showFilters ? '' : ''} lg:pl-4 lg:py-2.5 w-full`}>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 items-start">
          {pagesData?.map((pageData, index) => (
            <Fragment key={index}>
              <CartItemThumbGridMemo
                items={pageData}
                {...{ onShow, entitySpects, ...props }}
                classes={{ imgProportion }}
                renderAsGrid={false}
              />
            </Fragment>
          ))}
        </div>
        {!loading && emptyResults ? (
          <div className="text-center">
            No hay resultados.
          </div>
        ) : null} 
        
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            nextPage();
            setTimeout(() => ev.target.complete(), 400);
          }}
        >
          <IonInfiniteScrollContent>
          </IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </div>

      <div className="fixed bottom-4 right-4 md:right-6 min-w-40 pr-2 z-50 flex place-content-end">
        <ContactBTN className={'place-content-end'} />
      </div>

      <CurrentCartDetailsDrawer goToItemByParams={goToItemByParams} history={history} />
    </div>
  );
}